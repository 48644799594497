<template>
  <div />
</template>
<script>
  export default {
    name: 'Logout',

    metaInfo: {
      title: process.env.VUE_APP_TITLE + ' / Выход',
    },
    data() {
      return {}
    },
    mounted() {
      this.$store.commit('logout')
      this.$router.push('/login')
    },
  }
</script>
